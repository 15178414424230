import React, { useRef } from "react"
import styled from "styled-components"

import useLanguage from "../../states/context/websiteContext"
import useOnScreen from "../../utils/hooks/useOnScreen"
import { directionalDrillingData, directionalDrillingDataFr } from "../../data/directionalDrillingData"
import DetailsCard from "../cards/detailsCard"
import SectionTitle from "../titles/sectiontitle"
import { Language } from "../../states/context/websiteReducer"

const DirectionalDrillingSection = () => {
  const { language } = useLanguage()
  const isEnglish = language == Language.en
  const ref = useRef<HTMLDivElement | null>(null);
  const isVisible = useOnScreen(ref);

  return (
    <Wrapper ref={ref} className={isVisible ? "animate__animated animate__fadeIn animate__delay-0.8s" : undefined}>
      <SectionTitle title={isEnglish ? "What is directional drilling?" : "Qu'est-ce que le forage directionnel ?"} />
      {(isEnglish ? directionalDrillingData : directionalDrillingDataFr).map((data, index) => {
        return (
          <DetailsCard
            key={index}
            description={data.description}
            image={data.image}
          />
        )
      })}
    </Wrapper>
  )
}

export default DirectionalDrillingSection

const Wrapper = styled.div`
  max-width: 1140px;
  padding: 100px 40px;
  margin: 0 auto;
`
