import React from "react"
import styled from "styled-components"

import useWebsite from "../../states/context/websiteContext"

import { ActivePage, Language } from "../../states/context/websiteReducer"
import BlueButton from "../buttons/blueButton"

const HeroSection = () => {
  const { language, setActivePage } = useWebsite()
  
  return (
    <Wrapper>
      <Title className="animate__animated animate__fadeIn animate__delay-0.5s">{language == Language.en ? "Le Clan Jetté, for all your directional drilling needs" : "Le Clan Jetté: efficacité, qualité et rapidité pour tous vos besoins de forage directionnel!"}</Title>
      <BlueButton
        text={language == Language.en ? "Learn more" : "En apprendre plus"}
        onClick={() => {
          setActivePage(ActivePage.services)
          location.href = "/services"
        }}
      />
    </Wrapper>
  )
} 

export default HeroSection

const Wrapper = styled.div`
  background: url("/images/heroImage.png");
  background-repeat: no-repeat;
  padding: 0 40px;
  height: 600px;
  display: grid;
  justify-items: start;
  align-items: center;
`

const Title = styled.h1`
  color: white;
  font-weight: bold;
  font-size: 50px;
  line-height: 50px;
  width: 900px;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  @media (max-width: 950px) {
    width: 600px;
  }

  @media (max-width: 645px) {
    font-size: 40px;
    width: 300px;
  }
`
