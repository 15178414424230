export const advantagesData = [
  {
    image: "/images/piggyBank.svg",
    title: "Saves money",
    description: "Directional drilling does not require huge costs, compared to trench work.",
  },
  {
    image: "/images/timer.svg",
    title: "Reduced duration of work",
    description: "It is possible to carry out 500 meters of drilling a day, which greatly reduces the duration of the works!",
  }, 
  {
    image: "/images/form.svg",
    title: "Reduced authorization period",
    description: "Municipal applications for directional drilling typically require less time than other types of works.",
  },
  {
    image: "/images/speed.svg",
    title: "Speed of implementation",
    description: "With a reduced duration of work, it is even faster to set up ducts or aqueducts in no time!",
  },
  {
    image: "/images/check.svg",
    title: "No breakage at the surface",
    description: "Directional drilling does not create a trench; an underground horizontal drilling is performed, without harming the surrounding environment.",
  },
  {
    image: "/images/accuracy.svg",
    title: "Intense accuracy",
    description: "Through real-time technologies, the drilling angle can be precisely controlled, ensuring an intense accuracy of work.",
  },
]

export const advantagesDataFr = [
  {
    image: "/images/piggyBank.svg",
    title: "Économise de l'argent",
    description: "Le forage directionnel ne nécessite pas de coûts faramineux, par rapport aux travaux de tranchées.",
  },
  {
    image: "/images/timer.svg",
    title: "Durée des travaux réduite",
    description: "Il est possible de réaliser 500 mètres de forage par jour, ce qui réduit considérablement la durée des travaux !",
  }, 
  {
    image: "/images/form.svg",
    title: "Période d'approbation réduite",
    description: "Les demandes municipales pour forage directionnel nécessitent généralement moins de temps que d'autres types de travaux.",
  },
  {
    image: "/images/speed.svg",
    title: "Rapidité d'implantation",
    description: "Avec une durée de travail réduite, il est encore plus rapide de mettre en place des gaines ou des aqueducs en un rien de temps !",
  },
  {
    image: "/images/check.svg",
    title: "Pas de rupture à la surface",
    description: "Le forage directionnel ne crée pas de tranchée ; un forage horizontal souterrain est effectué, sans endommager à l'environnement.",
  },
  {
    image: "/images/accuracy.svg",
    title: "Précision intense",
    description: "Grâce aux technologies en temps réel, l'angle de perçage peut être contrôlé avec précision, ce qui garantit une grande précision de travail.",
  },
]