export const directionalDrillingData = [
  {
    description: "Directional drilling is a technology that allows the burial of a pipe under an obstacle without making a trench. This is done by performing a horizontal drilling, without damaging the environment. Directional drilling is ideal for installing electric pipes, gas pipes, water ducts, or telecommunication ducts under different types of ground, like roads, railways, rivers, buildings, earthworks or others, in a timely manner.",
    image: "/images/directionalDrillingimage.png",
  }
]

export const directionalDrillingDataFr = [
  {
    description: "Le forage directionnel est une technologie qui permet d'enfouir un tuyau sous un obstacle sans faire de tranchée. Cela se fait en réalisant un forage horizontal, sans endommager l'environnement. Le forage dirigé est idéal pour installer rapidement des conduites électriques, des conduites de gaz, des conduites d'eau ou des conduites de télécommunication sous différents types de terrains, comme les routes, les voies ferrées, les rivières, les bâtiments, les terrassements ou autres.",
    image: "/images/directionalDrillingimage.png",
  }
]