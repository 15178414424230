import React from "react"

import useWebiste, { WebsiteProvider } from "../states/context/websiteContext"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSection from "../components/sections/heroSection"
import AdvantagesSection from "../components/sections/advantagesSection"
import DirectionalDrillingSection from "../components/sections/directionalDrillingSection"
import { Language } from "../states/context/websiteReducer"

const IndexPage = () => {
  const { language } = useWebiste()
  const isEnglish = language == Language.en
  return (
    <Layout>
      <SEO lang={language} title={isEnglish ? "Home" : "Accueil"} />
      <HeroSection />
      <AdvantagesSection />
      <DirectionalDrillingSection />
    </Layout>
  )
}

export default IndexPage
