import React, { useRef } from "react"
import styled from "styled-components"

import useLanguage from "../../states/context/websiteContext"
import useOnScreen from "../../utils/hooks/useOnScreen"
import { advantagesData, advantagesDataFr } from "../../data/advantagesData"
import AdvantagesCard from "../cards/advantageCard"
import SectionTitle from "../titles/sectiontitle"
import { Language } from "../../states/context/websiteReducer"

const AdvantagesSection = () => {
  const { language } = useLanguage()
  const isEnglish = language == Language.en
  const ref = useRef<HTMLDivElement | null>(null);
  const isVisible = useOnScreen(ref);

  return (
    <Wrapper ref={ref} className={isVisible ? "animate__animated animate__fadeIn animate__delay-0.5s" : undefined}>
      <SectionTitle title={isEnglish ? "Our advantages" : "Nos avantages"} />
      <CardsWrapper>
        {(isEnglish ? advantagesData : advantagesDataFr).map((advantage, index) => {
          return (
            <AdvantagesCard
              key={index}
              image={advantage.image}
              title={advantage.title}
              description={advantage.description}
            />
          )
        })}
      </CardsWrapper>
    </Wrapper>
  )
}

export default AdvantagesSection

const Wrapper = styled.div`
  padding: 100px 40px;
  max-width: 1140px;
  margin: 0 auto;
`

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: auto;
  }
`
