import React from "react"
import styled from "styled-components"

interface Props {
  image: string
  title: string
  description: string
}
const AdvantagesCard = (props: Props) => {
  const { image, title, description } = props

  return (
    <Wrapper>
      <AdvantageImage src={image} />
      <p>
        <span>{title}: </span>
        {description}
      </p>
    </Wrapper>
  )
}

export default AdvantagesCard

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 60px auto;
  gap: 20px;
  align-items: center;
  letter-spacing: 0.1em;
  line-height: 40px;
  font-size: 15px;

  span {
    font-weight: 700;
  }

  p {
    max-width: 400px;
    text-align: justify;
  }

  @media (max-width: 768px) {
    p {
      max-width: fit-content;
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: auto;
  }
`

const AdvantageImage = styled.img`
  width: 60px;
  height: 60px;
`
